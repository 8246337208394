/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
/* App imports */
import TagList from '../../../components/tag-list'
import {header} from './heading.module.less'
import {titleDesign} from './heading.module.less'
import {coverImg} from './heading.module.less'

const Heading = ({ title, tags, cover, coverTitle }) => (
  <div className={header}>
    <div className={titleDesign}>
      <h1>{title}</h1>
      <TagList tags={tags} />
    </div>
    <div className={coverImg}>
      <Img fluid={cover} title={coverTitle} alt={title} />
    </div>
  </div>
)

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  cover: PropTypes.object.isRequired,
  coverTitle: PropTypes.string,
}

export default Heading
